.hero {
  background-color: white;
  position: relative;
  height: 50vh;
  overflow: hidden;
}

.hero__title {
  color: #2f55d4; /* Changed color for visibility */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  z-index: 1;
}

.cube {
  position: absolute;
  top: 80vh;
  left: 45vw;
  width: 10px;
  height: 10px;
  border: solid 1px #0040c1; /* Using a color instead of a function like darken */
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  animation: cube 12s ease-in forwards infinite;
}

.cube:nth-child(2n) {
  border-color: #0065ff; /* Using a color instead of lighten */
}

.cube:nth-child(2) {
  animation-delay: 2s;
  left: 25vw;
  top: 40vh;
}

.cube:nth-child(3) {
  animation-delay: 4s;
  left: 75vw;
  top: 50vh;
}

.cube:nth-child(4) {
  animation-delay: 6s;
  left: 90vw;
  top: 10vh;
}

.cube:nth-child(5) {
  animation-delay: 8s;
  left: 10vw;
  top: 85vh;
}

.cube:nth-child(6) {
  animation-delay: 10s;
  left: 50vw;
  top: 10vh;
}

@keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}

.head_line {
  font-size: 6rem !important;
  font-weight: 900 !important;
  transform: scaleY(1.6) !important;
  line-height: 1.6 !important;
  /* opacity: 1.0; */
  letter-spacing: -8px !important;
}

/* // X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
  .head_line {
    font-size: 5rem !important;
  }
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .head_line {
    font-size: 4.5rem !important;
  }
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .head_line {
    font-size: 4rem !important;
  }
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .head_line {
    font-size: 3rem !important;
  }
}

/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .head_line {
    font-size: 2rem !important;
    letter-spacing: -0px !important;
  }
}
